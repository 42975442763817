import { default as _91id_93kDOPeKyClwMeta } from "/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/connection-drivers/[id].vue?macro=true";
import { default as indexRmTQLJZ456Meta } from "/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/connection-drivers/index.vue?macro=true";
import { default as indexZN9t5PTg1SMeta } from "/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/index.vue?macro=true";
import { default as indexgKe0Y9uvhiMeta } from "/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/merchants/[id]/index.vue?macro=true";
import { default as merchant_45accesses9WAw3oD04MMeta } from "/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/merchants/[id]/merchant-accesses.vue?macro=true";
import { default as storesTBXjVVNF6LMeta } from "/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/merchants/[id]/stores.vue?macro=true";
import { default as terminalsFgeS8wXGnNMeta } from "/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/merchants/[id]/terminals.vue?macro=true";
import { default as _91id_93GMXdVAZSl7Meta } from "/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/merchants/[id].vue?macro=true";
import { default as indexJOvwDlVwWhMeta } from "/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/merchants/index.vue?macro=true";
import { default as _91id_93MpJ206i82LMeta } from "/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/users/[id].vue?macro=true";
import { default as indexBvHnNpn59OMeta } from "/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/users/index.vue?macro=true";
export default [
  {
    name: "connection-drivers-id",
    path: "/connection-drivers/:id()",
    component: () => import("/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/connection-drivers/[id].vue")
  },
  {
    name: "connection-drivers",
    path: "/connection-drivers",
    component: () => import("/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/connection-drivers/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/index.vue")
  },
  {
    name: _91id_93GMXdVAZSl7Meta?.name,
    path: "/merchants/:id()",
    component: () => import("/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/merchants/[id].vue"),
    children: [
  {
    name: "merchants-id",
    path: "",
    component: () => import("/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/merchants/[id]/index.vue")
  },
  {
    name: "merchants-id-merchant-accesses",
    path: "merchant-accesses",
    component: () => import("/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/merchants/[id]/merchant-accesses.vue")
  },
  {
    name: "merchants-id-stores",
    path: "stores",
    component: () => import("/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/merchants/[id]/stores.vue")
  },
  {
    name: "merchants-id-terminals",
    path: "terminals",
    component: () => import("/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/merchants/[id]/terminals.vue")
  }
]
  },
  {
    name: "merchants",
    path: "/merchants",
    component: () => import("/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/merchants/index.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    component: () => import("/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/users/[id].vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/var/www/sites/admin.staging.ultrafedt.dk/releases/20250124223719/pages/users/index.vue")
  }
]